<template>
  <div>
    <el-alert
      title="保健食品、农产品、医辅食品等特殊食品，请您联系客服处理"
      type="success"
      center
      show-icon
      description="注意：本次修改请您认真的检查是否填写正确，确保资料正确且齐全，以便耽误您的审核进度"
      style="width: 70%; margin: auto; margin-top: 20px"
      v-if="$route.query.modifyTagId"
    />
    <template v-else>
      <process ref="next_li" :name_list="name_list"></process>
      <el-alert
        v-if="[2, 3].includes(step)"
        title="保健食品、农产品、医辅食品等特殊食品，请您联系客服处理"
        type="warning"
        center
        show-icon
        description="规范：单份标签禁止 ( 填写/上传 ) 多个产品，请点击左下角   ‘ 添加更多食品标签 ’  进行增加"
        style="width: 70%; margin: auto; margin-top: 20px"
      />
    </template>

    <component
      ref="tagForm"
      @change="(...v) => propsParams.change && propsParams.change(...v)"
      @submit="(...v) => propsParams.submit && propsParams.submit(...v)"
      @store="(...v) => propsParams.store && propsParams.store(...v)"
      :is="propsParams.component"
      :showHint="true"
      :id="$route.query.id"
    />
  </div>
</template>

<script>
import { h } from 'vue';
import PrincipalContract from './components/principalContract';
import { Process } from '@/components/verify_firm';
import TagForm from './components/tag_form';
import TagPay from './components/tag_pay';
import Protocol from './components/protocol';
import BatchMode from './components/batchMode';
import SubmitSuccess from './components/submitSuccess';
import { pictureOrc } from '@/http/interface';

export default {
  name: 'apply_firm',
  components: {
    Process,
    Protocol,
    TagForm,
    TagPay,
    BatchMode,
    SubmitSuccess,
    PrincipalContract,
  },

  data() {
    return {
      propsParams: {
        change: this.setStep,
        component: 'protocol',
      },
      step: 1,
      name_list: {
        one: '同意审核服务协议',
        two: '提交标签资料',
        three: '支付费用',
        four: '申请成功',
      },
      resetId: '',
      labelsNum: 0,
      cmaInfo: [],
    };
  },
  watch: {
    step(v) {
      const valueObj = {
        1: {
          change: this.setStep,
          component: 'protocol',
        },
        2: {
          submit: this.submit_tag,
          component: this.isBatch ? 'BatchMode' : 'TagForm',
        },
        3: {
          change: this.setStep,
          component: 'TagPay',
        },
        4: {
          change: this.setStep,
          component: 'SubmitSuccess',
        },
      };
      this.propsParams = valueObj[v];
    },
    isBatch() {
      if (this.step === 2) {
        this.saveTagInfo();
        setTimeout(() => {
          this.propsParams = {
            submit: this.submit_tag,
            component: this.isBatch ? 'BatchMode' : 'TagForm',
          };
          this.resetTagInfo();
        });
      }
    },
  },
  computed: {
    isBatch() {
      return this.$store.getters.isBatch;
    },
    user() {
      return this.$store.getters.user;
    },
    cmaNum() {
      return this.cmaInfo.length;
    },
  },
  mounted() {
    this.resetTagInfo();
  },
  methods: {
    async resetTagInfo() {
      const { resetSubmitId, modifyTagId, state } = this.$route.query;
      if (!modifyTagId && !state) this.setStep(2);
      else {
        let step;
        if (state) {
          step = parseInt(state);
        } else {
          step = this.user.isAgreedProtocol ? 2 : 1;
        }
        this.setStep(step);
      }

      if (resetSubmitId || modifyTagId) {
        const { data } = await this.$api.orders_get(
          resetSubmitId || modifyTagId
        );
        setTimeout(() => this.$refs.tagForm.resetTag([data.orderDetail]));
      } else {
        this.watchRecord();
      }
    },
    async watchRecord() {
      if (this.step !== 2) return;
      try {
        const tagData = JSON.parse(localStorage.getItem('storeTag'));
        if (!tagData) return;
        await this.$confirm(
          '您上次编辑的内容没有提交,是否需要恢复 ？',
          '未提交数据',
          {
            confirmButtonText: '恢复',
            cancelButtonText: '放弃',
            type: 'warning',
          }
        );
        const info = JSON.parse(localStorage.getItem('storeTag'));
        setTimeout(() => this.$refs.tagForm.resetTag(info));
      } catch (error) {
        localStorage.removeItem('storeTag');
      }
    },
    setStep(step) {
      this.step = step;
      this.$refs.next_li && this.$refs.next_li.nexts(step);
    },
    async submit_tag(info) {
      const createEl = this.$createElement;
      const reSubmitTag = (callback) => {
        this.$msgbox({
          title: '一张标签中可能涉及了多款产品',
          message: `检测到您上传的标签设计稿中可能涉及了多款产品，这将影响标签的审核进度与质量，您可以通过“添加更多食品标签”的方式进行处理。`,
          showCancelButton: true,
          confirmButtonText: '确认不涉及多款产品，继续提交',
          cancelButtonText: '可能是的，我返回修改一下',
          type: 'warning',
          center: true,
          beforeClose: async (action, instance, done) => {
            try {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '执行中...';
                const { data } = await this.$api.orders_post(info, {
                  bypassMultiLabelsCheck: true,
                });
                callback(data);
              }
            } finally {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        });
      };

      if (!info || !info.length) {
        return this.$message.warning('没有任何标签可以提交。');
      }

      const { modifyTagId } = this.$route.query;
      if (modifyTagId) {
        await this.$confirm(
          '请认真检查是否按照要求修改了标签信息，以免耽误您的审核进度。',
          '修改标签',
          { confirmButtonText: '确定提交', cancelButtonText: '取 消' }
        );
        const modifyInfo = {
          ...info[0],
          tid: modifyTagId,
        };
        const { errcode } = await this.$api.orders_put(modifyInfo);
        if (errcode === 0) {
          this.$message.success('已将修改的标签重新提交');
          this.$router.push('/food');
        }
        return;
      }

      const needCMAReport = (labelsToSubmit) => {
        let required = false;
        for (let label of labelsToSubmit) {
          if (label.cma.required === true) {
            required = true;
            this.cmaInfo.push(label.cma);
          }
        }
        return required;
      };

      // 同步子组件传来的标签信息
      this.labelsNum = info.length;
      if (info.length > 0 && needCMAReport(info)) {
        // 写一个对话框，其中包含一个可以滚动的协议内容，然后让用户选择是否同意
        console.log('cma info', this.cmaInfo);
        const AgreementNode = (
          <div>
            <div class="agreement-tip">
              您已上传
              <span class="text-orange text-bold">{this.labelsNum}</span>
              份标签，提交后将无法修改内容, 请确认信息正确
              <br />
              另外，您还需要出具检测报告
              <span class="text-orange text-bold">{this.cmaNum}</span>
              份，请您仔细阅读以下委托协议，在继续提交前请选择
              <span class="text-orange text-bold">“同意协议”</span>
            </div>
            <div class="agreement">
              <PrincipalContract cma={this.cmaInfo[0]}></PrincipalContract>
            </div>
          </div>
        );
        try {
          let isAgree = await this.$msgbox({
            customClass: 'agreement-box',
            title: '委托协议',
            message: AgreementNode,
            confirmButtonText: '同意协议，继续提交',
            cancelButtonText: '不同意，返回修改',
            showCancelButton: true,
            beforeClose: async (action, instance, done) => {
              try {
                if (action === 'confirm') {
                  instance.confirmButtonLoading = true;
                  instance.confirmButtonText = '提交中...';
                  const { errcode, errmsg, data } = await this.$api.orders_post(
                    info
                  );
                  const jumpRouter = (ids) => {
                    localStorage.removeItem('form');
                    this.$router.push({
                      path: '/submit_tag',
                      query: { id: ids.join(','), state: 3 },
                    });
                  };
                  // 识别到一张图片中可能包含多款产品
                  if (errcode === 10001) {
                    setTimeout(() => reSubmitTag(jumpRouter));
                  } else if (errcode !== 0) {
                    this.$message.error(
                      '提交失败:' + errmsg || '请联系业务人员'
                    );
                  } else {
                    jumpRouter(data);
                  }
                }
              } finally {
                done();
                instance.confirmButtonLoading = false;
              }
            },
          });
          console.log('isAgree', isAgree);
        } catch (ex) {
          console.error(ex);
        }
        return;
      } else {
        this.$msgbox({
          title: '',
          message: createEl('p', null, [
            createEl(
              'div',
              null,
              `您已上传 ${info.length} 份标签，提交后将无法修改内容, 是否继续 ?`
            ),
            createEl(
              'div',
              { style: 'color: teal' },
              `"不合理的上传将影响审核进度 "`
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: '确定无误',
          cancelButtonText: '返回检查',
          beforeClose: async (action, instance, done) => {
            try {
              if (action === 'confirm') {
                instance.confirmButtonLoading = true;
                instance.confirmButtonText = '提交中...';
                const { errcode, errmsg, data } = await this.$api.orders_post(
                  info
                );
                const jumpRouter = (ids) => {
                  localStorage.removeItem('form');
                  this.$router.push({
                    path: '/submit_tag',
                    query: { id: ids.join(','), state: 3 },
                  });
                };
                // 识别到一张图片中可能包含多款产品
                if (errcode === 10001) {
                  setTimeout(() => reSubmitTag(jumpRouter));
                } else if (errcode !== 0) {
                  this.$message.error('提交失败:' + errmsg || '请联系业务人员');
                } else {
                  jumpRouter(data);
                }
              }
            } finally {
              done();
              instance.confirmButtonLoading = false;
            }
          },
        });
      }
    },
    saveTagInfo() {
      const storeTag = this.$refs.tagForm.getFormatTag();
      if (!storeTag || !storeTag.length) return;
      let notEmpty = true;
      if (storeTag && storeTag.length === 1) {
        notEmpty = storeTag.some((item) => {
          const {
            foodName,
            specs,
            remark,
            foodLabels = [],
            productStandard = {},
            cma = null,
          } = item;
          if (
            foodName ||
            (specs && specs !== '克x1件') ||
            remark ||
            foodLabels.length ||
            (productStandard.standardFile &&
              productStandard.standardFile.length) ||
            (cma && cma.required)
          ) {
            return true;
          }
          return false;
        });
      }
      if (!notEmpty) return;
      localStorage.setItem('storeTag', JSON.stringify(storeTag));
    },
  },
  // 如果在填写表单的时候退出页面就保存数据
  beforeDestroy() {
    if (this.step === 2) {
      this.saveTagInfo();
    } else {
      localStorage.removeItem('storeTag');
    }
  },
};
</script>

<style lang="scss" scoped>
.agreement-tip {
  margin-bottom: 10px;
}
.agreement {
  background: #f7f7f7;
  height: 50vh;
  overflow: auto;
  padding: 10px;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  margin-bottom: 10px;
}
</style>

<style lang="scss">
.agreement-box {
  width: 80vw;
}
</style>
