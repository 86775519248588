<template>
  <div class="tag_li_box">
    <div class="tag_box fwb">
      <el-row>
        <el-col :span="6">
          <div class="title text-cut" :title="tag_li.foodName">{{ tag_li.foodName }}</div>
        </el-col>
        <el-col :span="4">
          <div class="tag_num">
            <!-- 生产执行标准： -->
            规格：
            <span class="num">{{ tag_li.specs }}</span>
          </div>
        </el-col>
        <el-col :span="10">
          <!-- <div class="tag_info">生产商/受委托生产企业：{{tag_li.manufacturer }}</div> -->
          <div class="tag_info">备注：{{ tag_li.remark }}</div>
        </el-col>
        <el-col :span="4">
          <div class="tag_money">￥{{ tag_li.payment }}</div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tag_li"],
};
</script>

<style lang="scss" scoped>
.tag_li_box {
  width: 100%;
  .tag_box {
    padding: 0 30px;
    line-height: 50px;
    background: #ffffff;

    .tag_money {
      text-align: right;
    }
    .tag_info {
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: auto;
      width: 85%;
      .el-button {
        color: rgb(120, 120, 120);
      }
    }
    .tag_num {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .num {
        font-weight: normal;
      }
    }
  }
}
// .tag_hint {
//   width: 300px;
// }
</style>
