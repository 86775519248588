<template>
  <div>
    <div>
      <el-radio-group size="small" v-model="forma">
        <el-radio :label="true">常用格式</el-radio>
        <el-radio :label="false">其他格式</el-radio>
      </el-radio-group>
    </div>

    <div v-if="forma" class="flex align-center margin-top-m">
      <el-input-number
        placeholder="数量"
        size="small"
        v-model="numberForm.number1"
        :controls="false"
        :min="1"
      />
      <div>
        <el-dropdown @command="handleUnit">
          <span class="select">
            {{ numberForm.unit
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in ['克', '千克', '升', '毫升']"
              :key="index"
              :command="item"
              >{{ item }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-input-number
        placeholder="数量"
        size="small"
        v-model="numberForm.number2"
        :controls="false"
        :min="1"
      />
      <span class="margin-left-m">件</span>
    </div>
    <div class="margin-top-m" v-else>
      <el-input size="small" v-model="numberVal" placeholder="请输入规格" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["value"],
  watch: {
    numberForm: {
      handler() {
        const { number1, number2, unit } = this.numberForm;
        this.numberVal = number1 + unit + number2 + "件";
      },
      deep: true,
    },
    numberVal() {
      this.$emit("input", {
        numberForm: this.numberForm,
        numberVal: this.numberVal,
      });
    },
    value() {
      this.numberVal = this.value.numberVal;
    },
  },
  created() {
    if (this.value) {
      this.numberVal = this.value.numberVal;
    } else {
      this.forma = true;
    }
  },
  data() {
    return {
      numberForm: {
        number1: "",
        number2: "",
        unit: "千克",
      },
      numberVal: "",
      forma: false,
    };
  },
  methods: {
    handleUnit(v) {
      this.numberForm.unit = v;
    },
  },
};
</script>
<style lang="scss" scoped>
.select {
  display: inline-block;
  margin-left: 3px;
  margin-right: 3px;
  text-align: center;
  width: 55px;
}
</style>