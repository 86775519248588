<template>
  <div class="margin-top-sm">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column type="index" width="40" />
      <el-table-column prop="foodName" width="250">
        <template slot="header" slot-scope="scope">
          <span class="text-red">*</span> 食品名称
        </template>
        <template slot-scope="scope">
          <el-input
            placeholder="请输入食品名称"
            v-model="scope.row.foodName"
            size="small"
            class="input-height"
            type="textarea"
          />
        </template>
      </el-table-column>
      <el-table-column prop="foodLabels">
        <template slot="header" slot-scope="scope">
          <span class="text-red">*</span> 设计稿
          <el-tooltip placement="right-start">
            <div slot="content">
              请上传标签设计文件。（最多只能上传五份）<br />
              格式要求：支持.jpg .jpeg .bmp
              .png格式的图片以及word、pdf、excel、txt文档。<br />
              单个食品标签最多可上传5个设计文件，单个文件大小不超过100M。
            </div>
            <span class="el-icon text-black el-icon-question text-xs" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <batchUpload
            fileType="img"
            v-model="scope.row.foodLabels"
            :data="{
              type: 3,
              user,
            }"
            :limit="5"
          />
        </template>
      </el-table-column>
      <el-table-column prop="standardFile">
        <template slot="header" slot-scope="scope">
          执行标准
          <el-tooltip placement="right-start">
            <div slot="content">
              请上传企业标准文件。（可将该标准分成不超过20个文件上传）<br />
              格式要求：支持.jpg .jpeg .bmp
              .png格式的图片以及word、pdf、excel、txt文档。<br />
              单个文件大小不超过100M
            </div>
            <span class="el-icon text-black el-icon-question text-xs" />
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <batchUpload
            v-model="scope.row.standardFile"
            :data="{
              type: 4,
              user,
            }"
            :limit="20"
          />
        </template>
      </el-table-column>

      <el-table-column prop="specs" label="规格/含净量" width="300">
        <template slot-scope="scope">
          <InputSpec v-model="scope.row.specs" />
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="备注" width="200">
        <template slot-scope="scope">
          <el-input
            placeholder="备注信息"
            v-model="scope.row.remark"
            size="small"
            class="input-height"
            type="textarea"
          />
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="50">
        <template slot-scope="scope">
          <a
            class="text-red text-center text-df"
            @click="removeColumn(scope)"
            :disabled="!isDisabled"
          >
            <span class="el-icon-delete-solid"></span>
          </a>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="$route.query.modifyTagId" class="text-center margin-top-sm">
      <div>
        <el-button @click="$router.go(-1)" icon="el-icon-arrow-left"
          >暂不修改</el-button
        >
        <el-button type="primary" @click="submitTag"
          >提交标签修改资料
        </el-button>
      </div>
    </div>
    <template v-else>
      <div class="margin-top-sm">
        <el-button type="primary" @click="tableData.push({})"
          >新增一行</el-button
        >
      </div>
      <div class="text-center margin-top">
        <el-button type="primary" @click="submitTag"
          >提交标签申请审核
          <span class="el-icon el-icon-arrow-right" />
        </el-button>
      </div>
    </template>
  </div>
</template>
<script>
import batchUpload from "./batchUpload";
import InputSpec from "./InputSpec";
export default {
  components: { InputSpec, batchUpload },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isDisabled() {
      return this.tableData.length > 1;
    },
  },
  data() {
    return {
      tableData: [{}],
      value: "",
    };
  },
  methods: {
    async removeColumn(item) {
      await this.$confirm(
        `确定移除第 【${item.$index + 1}】 行吗 ？ 此操作会导致该行的数据丢失！`
      );
      this.tableData.splice(item.$index, 1);
    },
    submitTag() {
      const notEmpty = this.tableData.every((i, index) => {
        if (i.specs.numberVal && i.specs.numberVal.includes("undefined")) {
          this.$message.warning(`第${index + 1}行规格/含净量有误，请修改`);
          throw `第${index + 1}行规格出错`;
        }
        if (!i.foodLabels || !i.foodLabels.length) return false;
        if (!i.foodName) return false;
        return true;
      });
      if (!notEmpty)
        return this.$message.warning("【设计稿，食品名称】  为必填项");
      const info = this.getFormatTag();
      this.$emit("submit", info);
    },
    getFormatTag() {
      return this.tableData.map((item) => {
        delete item._id;
        const { standardFile = [], ...itemInfo } = item;
        return {
          ...itemInfo,
          specs: itemInfo.specs.numberVal,
          productStandard: {
            standardFile,
          },
        };
      });
    },
    resetTag(params = []) {
      if (!params.length) return;
      this.tableData = params.map((item) => {
        const standardFile =
          item.productStandard && item.productStandard.standardFile
            ? item.productStandard.standardFile
            : [];
        delete item.productStandard;
        return {
          ...item,
          specs: {
            numberVal: item.specs,
          },
          foodLabels: item.foodLabels || [],
          standardFile,
        };
      });
    },
  },
};
</script>

<style lang="scss"  scoped>
.input-height {
  textarea {
    height: 63px !important;
  }
}
</style>