<template>
  <div class="protocol_box">
    <div class="protocol_text">
      <iframe
        src="https://www.shihaishibiao.com/docs/contract.html?ctxonly=1"
        frameborder="0"
      ></iframe>
    </div>
    <el-checkbox v-model="checked" v-if="showHint" class="margin-top-m" style="color: black"
      >我同意并下次不再提示</el-checkbox
    >
    <div class="protocol_btn tac">
      <el-button type="primary" @click="set_btn" style="color: black">
        同意条款并进行认证
        <i class="el-icon-arrow-right el-icon--right"></i>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showHint"],
  data() {
    return {
      checked: false,
    };
  },
  methods: {
    async set_btn() {
      if (this.showHint && this.checked) {
        const { errcode } = await this.$api.protocol();
        if (errcode) return;
      }
      this.$emit("change", 2);
    },
  },
};
</script>

<style lang="scss" >
.protocol_box {
  height: 60vh;
  width: 850px;
  margin: auto;
  margin-top: 20px;
  .protocol_text {
    background: #ffffff;
    height: 100%;
    border: inset 3px #fefefe;

    iframe {
      width: 100%;
      height: 100%;
    }
    .el-textarea__inner {
      background: #333333;
      color: #ffffff;
    }
  }
  .protocol_btn {
    margin-top: 50px;
  }
}
</style>
