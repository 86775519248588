<template>
  <div class="tag_pay_box">
    <div class="flex margin-top">
      <div class="flex-sub text-bold">食品标签明细</div>
      <div>
        应付：
        <span class="text-red text-bold show-price">{{ goodsPriceTotal }}</span>
      </div>
    </div>
    <div class="pay_goods_list">
      <el-collapse accordion>
        <el-collapse-item
          class="pay_goods_li"
          v-for="item in orderList"
          :key="item.id"
        >
          <template slot="title">
            <tag-li :tag_li="item"></tag-li>
          </template>
          <div class="goods_hint">
            <div>
              <span class="title">生产执行标准：</span>
              {{ item.standardNumber }}
            </div>
            <div class="bj">
              <span class="title">生产商/受委托生产企业：</span>
              {{ item.manufacturer }}
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <div class="flex align-center margin-top-sm">
      <div class="flex-sub">
        <el-checkbox v-model="openBalance" class="text-bold">
          <span class="text-df text-black"> 可用余额：{{ balanceTotal | amount }} </span>
        </el-checkbox>
      </div>
      <div>
        抵扣：
        <span class="text-red text-bold text-lg show-price">{{
          useBalance
        }}</span>
      </div>
    </div>
    <div class="margin-top" v-if="paymentAmount">
      <div class="flex align-center solid-bottom padding-tb-xs">
        <div class="flex-sub text-bold">其他支付方式</div>
        <div>
          支付：
          <span class="text-red text-bold text-lg show-price">{{
            paymentAmount
          }}</span>
        </div>
      </div>

      <div>
        <div class="padding-tb">
          <el-radio checked class="flex align-center">
            <div class="wx-logo flex align-center">
              <span class="iconfont iconwxzhifu margin-right-m"></span>
              <span class="text">微信支付</span>
            </div>
          </el-radio>
        </div>
      </div>
    </div>

    <div class="margin-top">
      <div class="padding-top-sm solid-top">
        <div class="flex align-center">
          <div class="flex-sub">
            <span class="text-bold">使用优惠券</span>

            <el-button
              type="primary"
              class="margin-lr-sm"
              size="small"
              icon="el-icon-sold-out"
              @click="onExchange"
              >兑换优惠券</el-button
            >
            <el-button
              v-if="couponsList.length"
              size="small"
              icon="el-icon-s-ticket"
              @click="showCoupons = true"
              plain
            >
              <el-badge :is-dot="!currentCoupons.couponCode">
                {{
                  currentCoupons.couponCode ? '更换优惠券' : '您有优惠券未使用'
                }}
              </el-badge>
            </el-button>
          </div>
          <div v-if="couponsPrice">
            抵扣：
            <span class="text-red text-bold text-lg show-price">{{
              couponsPrice
            }}</span>
          </div>
        </div>
        <div class="margin-top-sm" style="width: 300px">
          <div v-if="currentCoupons.couponCode">
            <CouponsCard :item="currentCoupons" />
          </div>
        </div>

        <div
          v-if="couponsList.length"
          style="background-color: #f6f6f6"
          class="text-sm padding-xs"
        >
          <div v-if="couponsPriceText">
            <span v-html="couponsPriceText"></span>
            <!-- <span class="margin-left-sm text-gray">使用优惠券 1 张</span> -->
          </div>
          <div v-else class="text-gray">
            <span class="text-black margin-right-sm">未选中优惠券</span>
            使用优惠券，支付更优惠哦 ~
          </div>
        </div>
      </div>
    </div>

    <div
      style="background-color: #f4f4f4"
      class="padding-sm padding-tb margin-top-xl solid"
    >
      <div
        v-if="false"
        class="flex bg-white align-center padding-xs padding-lr-sm rebate-card"
      >
        <div class="margin-right-xs">
          <el-checkbox v-model="openBalance" size="medium" />
        </div>
        <div class="flex-sub">
          <div>使用会员余额支付</div>
          <div class="text-gray text-xs">可使用：{{ balanceTotal }}</div>
        </div>
        <div>
          <span v-if="balanceTotal" class="text-red text-bold text-lg"
            >-{{ useBalance }}</span
          >
          <el-button
            @click="$router.push('/add_money')"
            type="text"
            plain
            v-else
            class="text-sm text-orange"
            >点击去充值，让支付更便捷。</el-button
          >
        </div>
      </div>
      <div v-if="false">
        <div class="margin-top flex align-center justify-end">
          <span class="text-sm text-grey">商品总额：</span>
          <div class="show-price text-right">
            <span class="text-red text-bold">{{ goodsPriceTotal }}</span>
          </div>
        </div>
        <div class="text-right margin-top-xs flex align-center justify-end">
          <span class="text-sm text-grey">使用余额：</span>
          <div class="show-price">
            <span class="text-red text-bold">- {{ useBalance }}</span>
          </div>
        </div>
        <div class="text-right margin-top-xs flex align-center justify-end">
          <span class="text-sm text-grey">商品优惠：</span>
          <div class="show-price">
            <span class="text-red text-bold">-{{ couponsPrice }}</span>
          </div>
        </div>
      </div>
      <div class="margin-top text-right">
        <div class="flex align-center justify-end margin-bottom-sm">
          <div>
            应付金额
            <span>
              {{ goodsPriceTotal }}
            </span>
          </div>
          <div>
            <span class="margin-lr-xs">-</span>
            使用余额
            <span>
              {{ useBalance }}
            </span>
          </div>
          <div v-if="couponsPrice">
            <span class="margin-lr-xs">-</span>
            使用优惠券
            <span>
              {{ couponsPrice }}
            </span>
          </div>
        </div>
        <div>
          <span>实付金额：</span>
          <span class="text-red text-bold text-xxl">{{ paymentAmount }}</span>
        </div>
        <div class="margin-top-xs">
          <el-button
            round
            icon="el-icon-arrow-left"
            @click="$router.push('/food')"
          >
            暂不支付
          </el-button>
          <el-button type="primary" round @click="set_btn"
            >确认支付订单</el-button
          >
        </div>
      </div>
    </div>

    <!-- 弹窗区域 -->
    <SelectCoupons
      :list="couponsList"
      :currentCoupons="currentCoupons"
      v-model="showCoupons"
      @change="onCouponsCard"
    />
    <div class="pop_code" v-show="showPay">
      <div class="pop_box">
        <div class="title">
          <div class="name">
            <h3><span class="iconfont iconwxzhifu"></span>微信支付</h3>
          </div>
          <el-button
            @click="off_pop"
            class="title_btn"
            type="text"
            icon="el-icon-close"
          ></el-button>
        </div>
        <div class="code_text">
          应付金额：
          <span class="money fwb">{{ paymentAmount }}</span>
        </div>
        <div class="code_img">
          <div id="qrcode" class="dib" ref="qrcode"></div>
        </div>
        <div class="wx_pay tac">
          <img src="/static/img/wx.png" alt />
        </div>
      </div>
    </div>
    <div class="pop_bg" v-show="showPay"></div>
    <div class="tell_code" v-if="payCode">
      <div class="box_wrap">
        <div class="box">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>余额支付确认</span>
            </div>
            <el-form size="small" label-width="100px" label-position="left">
              <el-form-item label="现金支付：" style="margin-bottom: 0">{{
                paymentAmount
              }}</el-form-item>
              <el-form-item label="余额支付：" style="margin-bottom: 0">{{
                useBalance
              }}</el-form-item>
              <el-form-item label="付款后余额：" style="margin-bottom: 0">{{
                balanceTotal - useBalance
              }}</el-form-item>
              <el-form-item label="短信验证码：" style="margin-bottom: 0">
                <el-input
                  class="dib"
                  style="width: 60%"
                  v-model="code"
                ></el-input>
                <el-button class="dib" v-if="showCode" disabled
                  >获取验证码{{ TimeReduce }}s</el-button
                >
                <el-button class="dib" v-else @click="set_btn"
                  >重新获取</el-button
                >
              </el-form-item>
              <el-form-item style="margin-bottom: 0"
                >请用手机号
                {{ user.mobile.replace(/(\d{3})(\d{4})/, '$1****') }}
                查收验证码！</el-form-item
              >
              <el-form-item size="large" style="margin-top: 20px">
                <el-button size="small" @click="payCode = false"
                  >取消支付</el-button
                >
                <el-button type="primary" size="small" @click="code_order"
                  >确认支付</el-button
                >
              </el-form-item>
            </el-form>
          </el-card>
        </div>
      </div>
    </div>
    <div class="pop_bg" v-if="payCode"></div>
  </div>
</template>

<script>
import TagLi from './tag_li';
import CouponsCard from './couponsCard.vue';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { getCouponsList } from '../service';
import { stream } from 'exceljs';
import SelectCoupons from './selectCoupons.vue';
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    TagLi,
    CouponsCard,
    SelectCoupons,
  },
  computed: {
    ...mapGetters(['user']),
    couponsPriceText() {
      const { activity } = this.currentCoupons;
      if (!activity) return '';
      if (activity.couponType === 0) {
        return `优惠券将直接抵扣：<span class='text-red text-bold'>${activity.price}</span> 元`;
      }
      if (activity.couponType === 1) {
        return `已达满减要求，优惠券将抵扣：<span class='text-red text-bold'>${activity.price}</span> 元`;
      }
      if (activity.couponType === 2) {
        return `使用折扣优惠券，预计折扣：<span class='text-red text-bold'>${String(
          activity.discount
        )
          .split('')
          .join('.')}折</span>`;
      }
      return '';
    },
    couponsPrice() {
      const { activity, couponDiscount } = this.currentCoupons;
      if (!activity) return 0;
      return couponDiscount;
    },
  },
  data() {
    return {
      currentCoupons: {},
      couponsList: [],
      code: '',
      TimeReduce: 60,
      payCode: false,
      showCoupons: false, // 显示选择优惠券
      reCodeTime: null,
      showCode: true,

      // 商品总价格
      goodsPriceTotal: 0,

      // 我的钱包余额
      balanceTotal: 0,

      // 使用余额抵扣的金额
      useBalance: 0,

      // 需要支付的金额
      paymentAmount: 0,

      // 是否使用余额支付
      openBalance: true,

      orderList: [],
      showPay: false,
      poll_status: null,
    };
  },
  watch: {
    openBalance(e) {
      this.priceChange();
      this.onGetCouponsList();
    },
    couponsPrice() {
      this.priceChange();
    },
  },
  async mounted() {
    const { data } = await this.$api.payment({ tids: this.id });
    console.log('mounted', this.id, data);
    this.balanceTotal = data.account.balance;

    let goodsPriceTotal = this.goodsPriceTotal;
    this.orderList = data.orders.map((order) => {
      const { productStandard = {} } = order;
      goodsPriceTotal += order.payment;
      return {
        ...order,
        standardNumber: productStandard.standardNumber
          ? productStandard.standardNumber
          : '',
      };
    });
    this.goodsPriceTotal = goodsPriceTotal;

    this.priceChange();

    // 获取优惠券列表
    this.onGetCouponsList();
  },
  methods: {
    // 计算需要支付的金额
    priceChange() {
      if (this.openBalance) {
        // 使用余额+优惠券是否足够抵扣订单

        if (this.balanceTotal + this.couponsPrice >= this.goodsPriceTotal) {
          // 余额减去 商品 - 优惠券 = 使用掉的余额
          this.useBalance = _.round(this.goodsPriceTotal - this.couponsPrice, 2);
          this.paymentAmount = 0;
        } else {
          // 余额不够时 , 使用优惠券后是否足够；
          if (this.balanceTotal >= this.goodsPriceTotal - this.couponsPrice) {
            this.useBalance = _.round(this.goodsPriceTotal - this.couponsPrice, 2);
            this.paymentAmount = 0;
          } else {
            // 支出全部余额
            this.useBalance = this.balanceTotal;
            this.paymentAmount =
              _.round(this.goodsPriceTotal - this.balanceTotal - this.couponsPrice, 2);
          }
        }
      } else {
        this.useBalance = 0;
        // 优惠券就能够白嫖
        if (this.couponsPrice >= this.goodsPriceTotal) {
          this.paymentAmount = 0;
        } else {
          this.paymentAmount = _.round(this.goodsPriceTotal - this.couponsPrice, 2);
        }
      }
    },

    onExchange() {
      this.$prompt('请输入优惠券码', '使用优惠券码兑换', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[^\u4e00-\u9fa5]+$/,
        closeOnClickModal: false,
        inputErrorMessage: '不能包含汉字哦 ~',
      }).then(async ({ value }) => {
        const { data } = await this.$api.exchangeCoupons({
          couponCode: value,
        });
        await this.onGetCouponsList();
        this.$message.success('优惠券兑换成功。');
      });
    },
    async onGetCouponsList() {
      this.currentCoupons = {};
      const { data } = await getCouponsList({
        tids: this.id.split(','),
        useBalance: this.openBalance,
        couponCode: '',
      });
      this.couponsList = data;
      if (data.length) {
        this.currentCoupons = data[0];
      }
    },
    onCouponsCard(values) {
      if (values) {
        this.currentCoupons = values;
      } else {
        this.currentCoupons = {};
      }
      this.showCoupons = false;
    },
    async code_order() {
      if (!this.code) {
        this.$message.error('验证码不能为空！');
        return false;
      }
      await this.$api.mobile_put({ mobileCode: this.code });

      clearInterval(this.reCodeTime);
      this.reCodeTime = null;
      const paymentInfo = await this.$api.payment_post({
        tids: this.id.split(','),
        payment: _.round(this.goodsPriceTotal - this.couponsPrice, 2),
        useBalance: this.openBalance,
        balance: _.round(this.useBalance, 2),
        realPayment: _.round(this.paymentAmount, 2),
        couponCode: this.currentCoupons.couponCode,
      });
      if (paymentInfo.errcode === 0) {
        this.$message.success('恭喜您，订单支付成功~');
        this.payCode = false;
        this.$emit('change', 4);
      }
    },
    async set_btn() {
      if (this.paymentAmount === 0) {
        // 不需要付款，只需要验证码
        this.payCode = true;
        this.showCode = true;
        if (this.TimeReduce === 60) {
          const { errcode } = await this.$api.mobile_post();
          if (errcode === 0) {
            this.payCode = true;
            this.$message.success('短信发送成功');
            this.reCodeTime = setInterval(() => {
              if (this.TimeReduce > 1) {
                this.TimeReduce--;
              } else {
                clearInterval(this.reCodeTime);
                this.TimeReduce = 60;
                this.reCodeTime = null;
                this.showCode = false;
              }
            }, 1000);
          }
        }
      } else {
        // 需要支付剩下的金额
        const { data, errcode } = await this.$api.payment_post({
          tids: this.id.split(','),
          payment: _.round(this.goodsPriceTotal - this.couponsPrice, 2),
          useBalance: this.openBalance,
          balance: _.round(this.useBalance, 2),
          realPayment: _.round(this.paymentAmount, 2),
          couponCode: this.currentCoupons.couponCode,
        });
        if (errcode === 0) {
          if (!data) {
            this.$message.success('恭喜您，订单支付成功~');
            this.$emit('change', 4);
          } else {
            const paymentID = data.out_trade_no;
            this.$QRCodeSC(data.code_url);
            this.showPay = true;
            if (paymentID) this.poll(paymentID);
          }
        }
      }
    },

    async poll(paymentID) {
      const { data } = await this.$api.get_pay(paymentID);
      if (data.status === 2) {
        this.$message.success('恭喜您，订单支付成功~');
        clearTimeout(this.poll_status);
        this.poll_status = null;
        this.showPay = false;
        this.$emit('change', 4);
      } else {
        this.poll_status = setTimeout(() => {
          this.poll(paymentID);
        }, 1000);
      }
    },
    off_pop() {
      document.getElementById('qrcode').innerHTML = '';
      this.showPay = false;
      clearTimeout(this.poll_status);
    },
  },
  destroyed() {
    this.poll_status && clearTimeout(this.poll_status);
    this.reCodeTime && clearInterval(this.reCodeTime);
    this.poll_status = null;
    this.reCodeTime = null;
  },
};
</script>
<style lang="scss" scoped>
$red: rgb(255, 0, 0);

.show-price {
  width: 100px;
  display: inline-block;
  text-align: right;
}
.tag_pay_box {
  max-width: 1200px;
  margin: auto;
  color: #000000;
  .coupons-card-wrap {
    margin-left: -10px;
    margin-right: -10px;
  }
  .show-price {
    width: 100px;
    display: inline-block;
  }
  .rebate-card {
    width: 600px;
    margin: auto;
    ::v-deep {
      .el-checkbox__inner {
        width: 30px;
        height: 30px;
        border-width: 2px;
      }
      .el-checkbox__inner::after {
        border-width: 3px;
        height: 14px;
        left: 10px;
        top: 3px;
        width: 6px;
      }
    }
  }
  .wx-logo {
    background: #ffffff;
    padding: 6px 12px;
    font-size: 16px;
    border: 1px solid rgb(213, 213, 213);
    vertical-align: middle;
    .iconfont {
      color: rgb(44, 163, 57);
      font-size: 21px;
      vertical-align: middle;
    }
    .text {
      color: #000000;
    }
  }
  .pay_title {
    padding: 0 30px;
    .title {
      vertical-align: middle;
      line-height: 65px;
      font-size: 16px;
      color: #000000;
      .money {
        color: $red;
        min-width: 90px;
        text-align: right;
      }
    }
  }
  .pay_line {
    border-bottom: 1px solid rgb(227, 225, 225);
  }
  .pay_wx {
    padding-right: 30px;

    .pay_count {
      line-height: 60px;
      .money {
        color: $red;
        min-width: 90px;
        text-align: right;
      }
    }
  }
  .pay_btn {
    .set_btn {
      margin-left: 30px;
    }
  }
  .pay_goods_list {
    .pay_goods_li {
      margin: 8px 0;
      .goods_hint {
        padding: 0 30px;
        color: darkgray;
        .bj {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .title {
          width: 150px;
          display: inline-block;
          text-align: left;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
  .pop_bg {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 2005;
  }
  .pop_code {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 300px;
    text-align: center;
    z-index: 2006;
    .pop_box {
      display: inline-block;
      width: 350px;
      padding-bottom: 10px;
      vertical-align: middle;
      background-color: #ffffff;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      font-size: 18px;
      -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      text-align: left;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      padding-bottom: 30px;
      .wx_pay {
        img {
          width: 210px;
        }
      }
      .code_text {
        text-align: center;
      }
      .title {
        position: relative;
        padding: 15px;
        padding-bottom: 10px;
        padding-top: 30px;

        .name {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding-left: 0;
          margin-bottom: 0;
          font-size: 20px;
          line-height: 1;
          color: #303133;
          vertical-align: bottom;
          line-height: 40px;
          h3 {
            vertical-align: bottom;
            line-height: 40px;
          }
          .iconfont {
            vertical-align: bottom;
            font-size: 25px;
            margin-right: 5px;
            color: #44b549;
          }
        }
        .title_btn {
          position: absolute;
          top: 5px;
          right: 5px;
          padding: 0;
          border: none;
          outline: none;
          background: transparent;
          font-size: 16px;
          padding: 15px;
          cursor: pointer;
        }
      }
      .code_img {
        padding-left: 27px;
        padding-right: 27px;
        text-align: center;
        position: relative;
        padding: 10px 15px;
        font-size: 0;
        line-height: 0;
      }
    }
  }
  .tell_code {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2006;
    vertical-align: middle;
    .box_wrap {
      width: 100%;
      display: inline-block;
      position: relative;
      vertical-align: middle;
    }
    .box {
      width: 500px;
      margin: auto;
    }
  }
  .tell_code:after {
    content: '';
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
}
</style>
