<template>
  <div>
    <el-drawer
      title="我的优惠券列表"
      :visible.sync="drawer"
      :before-close="handleClose"
      append-to-body
    >
      <div class="margin-lr-sm coupons-drawer">
        <div
          v-for="item in list"
          :key="item.couponCode"
          @click="$emit('change', item)"
        >
          <CouponsCard
            :class="
              item.couponCode === currentCoupons.couponCode
                ? 'coupons-active'
                : ''
            "
            :item="item"
          />
        </div>
      </div>

      <div class="foot-btn solid-top">
        <el-button
          type="danger"
          block
          style="width: 100%"
          @click="$emit('change')"
          ><span class="text-white">不使用优惠券</span></el-button
        >
      </div>
    </el-drawer>
  </div>
</template>

<script>
import CouponsCard from "./couponsCard.vue";
export default {
  components: {
    CouponsCard,
  },
  props: {
    value: {},
    list: {
      default: [],
    },
    currentCoupons: {
      default: {},
    },
  },
  watch: {
    value() {
      this.drawer = this.value;
    },
    drawer() {
      this.$emit("input", this.drawer);
    },
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    handleClose() {
      this.drawer = false;
    },
    onCouponsCard() {},
  },
};
</script>
<style lang="scss" scoped>
.foot-btn {
  position: absolute;
  bottom: 0;
  padding: 20px;
  left: 0;
  right: 0;
  background-color: #fff;
  text-align: center;
}
.coupons-drawer {
  padding-bottom: 100px;
}
</style>