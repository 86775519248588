<template>
  <div class="coupons-wrap">
    <div class="solid margin-bottom-sm" @click="$emit('change', item)">
      <div class="text-center padding-tb-m coupons-bg shadow" :class="bgColor">
        <div v-if="activity.couponType === 0">
          <div>
            <span class="price-number text-bold text-price">
              {{ activity.price }}
            </span>
          </div>
          <div>
            <span class="cu-tag bg-white sm" v-if="activity.canOverlay"
              >可叠加</span
            >
            <span class="cu-tag radius bg-white sm">无门槛优惠券</span>
          </div>
        </div>
        <div v-else-if="activity.couponType === 1">
          <div>
            <span class="text-price price-number text-bold">{{
              activity.price
            }}</span
            >券
          </div>
          <div>
            <span class="cu-tag bg-white sm" v-if="activity.canOverlay"
              >可叠加</span
            >
            <span class="cu-tag radius bg-white sm"
              >满{{ activity.threshold }}元可用</span
            >
          </div>
        </div>
        <div v-else-if="activity.couponType === 2">
          <div>
            <span class="price-number text-bold">{{
              String(activity.discount).split("").join(".")
            }}</span
            >折
          </div>
          <div>
            <span class="cu-tag bg-white sm" v-if="activity.canOverlay"
              >可叠加</span
            >
          </div>
        </div>
        <div class="text-sm padding-top-m date">
          <span class="text-sm">
            {{ couponsTimeRange(activity.term.from, activity.term.to) }}
          </span>
        </div>
      </div>
      <div class="text-left padding-lr-xs text-list text-sm">
        <div class="margin-tb-m text-cut" :title="item.couponCode">
          <span>券编号：</span>
          <span class="text-gray">{{ item.couponCode }}</span>
        </div>
      </div>
      <div class="tips-right text-lg">
        <div class="tips"></div>
        <div class="text text-bold">
          <i class="el-icon-check"></i>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {},
  },
  computed: {
    activity() {
      return this.item.activity || {};
    },
    bgColor() {
      return {
        0: "bg-gradual-orange",
        1: "bg-gradual-purple",
        2: "bg-gradual-pink",
      }[this.activity.couponType];
    },
    useStatus() {
      if (this.item.status === 3) return "runOut";
      return moment(this.item.validTo).diff(moment(), "seconds") < 0
        ? "timeout"
        : "use";
    },
  },
};
</script>
<style lang="scss" scoped>
.coupons-wrap {
  cursor: pointer;
  .tips-right {
    display: none;
  }
}
.coupons-wrap:hover {
  .solid::after {
    border: 2px solid red;
  }
}

.coupons-active {
  position: relative;

  .tips-right {
    display: inherit;
    position: absolute;
    top: 0;
    z-index: 1;
    right: 0;
    .tips {
      position: relative;
    }
    .text {
      padding: 3px;
      color: var(--white);
      cursor: pointer;
    }
    .tips:before {
      position: absolute;
      content: "";
      z-index: -1;
      border-color: var(--red) var(--red) transparent transparent;
      border-width: 20px 20px 20px 20px;
      border-style: solid;
      right: 0;
    }
  }
}
</style>