<template>
  <div class="flex batchUpload">
    <el-upload
      action="https://upload.qiniup.com/"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :http-request="uploadRequest"
      :before-upload="beforeUpload"
      :on-success="handleSuccess"
      :limit="limit"
      :data="data"
      accept=".jpg, .jpeg, .bmp, .png, .pdf, .doc, .excel, .txt, .docx, .xlsx"
      :show-file-list="false"
      class="flex align-center"
    >
      <el-tooltip
        effect="dark"
        :content="`已上传 ${fileList.length} 份;【 ${fileList
          .map((i) => i.originalname)
          .join(' ，')} 】`"
      >
        <a
          class="text-blue"
          :element-loading-text="`${parseInt(percent)}%`"
          element-loading-spinner="el-icon-loading"
          v-loading="loading"
        >
          <span class="el-icon-upload2 text-xl" />
        </a>
      </el-tooltip>
    </el-upload>
    <div class="flex-sub margin-left-xs">
      <div class="height">
        <span v-if="!fileList.length" class="text-gray"> 
          点击图标上传文件 </span>
        <div
          class="flex align-center clickUrl"
          v-for="(item, index) in fileList"
          :key="item.originalname + index"
          @click="handlePreview(item)"
          :title="item.originalname"
        >
          <span class="flex-sub text-cut text-blue">
            {{ item.originalname }}
          </span>
          <span
            class="el-icon el-icon-success success text-green text-xs padding-left-xs"
          />
          <span
            :title="`点击删除该 [ ${item.originalname} ] 文件`"
            class="el-icon el-icon-close close text-xs padding-left-sm"
            @click="(e) => handleClose(item, e)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { upload } from "@/assets/js/validate";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    value: {},
    fileType: String,
  },
  data() {
    return {
      fileList: [],
      loading: false,
      percent: 0,
    };
  },
  watch: {
    value(v) {
      if (v && v.length) {
        this.fileList = v;
      }
    },
    loading() {
      if (!this.loading) this.percent = 0;
    },
    fileList() {
      this.$emit("input", this.fileList);
      this.$emit("change", this.fileList);
    },
  },
  created() {
    this.fileList = this.value || [];
  },
  methods: {
    beforeUpload(file) {
      if (this.loading) {
        this.$message.warning("有文件正在上传，请稍等..");
        return false;
      }
      let fileName = file.name;
      let regex = /(.pdf|.docx|.doc|.txt|.excel|.jpg|.jpeg|.bmp|.png|.xlsx)$/;
      if (!regex.test(fileName.toLowerCase())) {
        this.$message.error("该文件类型暂未支持,请选中正确文件!");
        return false;
      }
      const imageSize = file.size / 1024 / 1024 < 100; //上传图片大小不超过10M
      if (!imageSize) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return false;
      }
      return imageSize;
    },
    async uploadRequest(request) {
      const uploadConfig = await this.$api.upload_get({
        type: request.data.type,
      });
      const { token } = uploadConfig.data;
      request.user = request.data.user;
      request.type = request.data.type;
      this.loading = true;
      upload(
        token,
        request,
        (next) => {
          this.percent = next.total.percent;
          request.file.percent = next.total.percent;
          request.onProgress(request.file);
        },
        (error) => {
          this.loading = false;
          this.$message.error(`上传失败：${error.message}`);
        },
        request.onSuccess
      );
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.limit} 个文件，本次选择了 ${
          files.length
        } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      );
    },
    async handleClose(file, e) {
      e && e.stopPropagation();
      await this.$confirm(`确定移除 ${file.originalname}？`);
      this.fileList = this.fileList.filter((i) => i.url !== file.url);
    },
    handleSuccess(data, file, fileList) {
      if (!data) return;
      if (!data.url)
        return this.$message.warning(`上传失败 ${JSON.stringify(data)}`);
      this.fileList.push(data);
      this.loading = false;
    },
    handlePreview(file) {
      const regex = /(.jpg|.jpeg|.bmp|.png)$/;
      if (regex.test(file.originalname)) {
        const h = this.$createElement;
        this.$msgbox({
          title: `预览图片：${file.originalname}`,
          message: h("div", {
            src: file.url,
            title: "查看图片",
            style: {
              width: "100%",
              minHeight: "300px",
              height: "auto",
              textAlign: "center",
              fontSize: 0,
            },
            domProps: {
              innerHTML: `<img style="width:100%;height:auto;" src="${file.url}"></img>`,
            },
          }),
        });
      } else {
        window.open(file, url, "_blank");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.batchUpload {
  .show-img {
    width: 40px;
    height: 40px;
    img {
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }
  .height {
    min-height: 50px;
    // overflow-y: scroll;
    padding: 0px 2px;
  }
  .clickUrl {
    cursor: pointer;

    .close {
      display: none;
    }
  }
  .clickUrl:hover {
    .close {
      display: block;
    }
    .success {
      display: none;
    }
  }
}
</style>