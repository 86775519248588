<template>
  <div class="norm_info_box">
    <el-form
      :model="tag_form"
      ref="tag_form"
      label-width="120px"
      class="demo-dynamic"
    >
      <div
        class="norm_list"
        v-for="(domain, index) in tag_form.domains"
        :key="index"
      >
        <div class="clr remove_btn" v-if="index != 0">
          <el-button class="fl fwb black" size="small" type="text"
            >食品标签{{ index + 1 }}</el-button
          >
          <el-button
            icon="el-icon-delete"
            type="text"
            class="fr"
            circle
            @click.prevent="removeDomain(domain)"
          />
        </div>
        <el-form-item
          :label="'标签设计稿'"
          :prop="'domains.' + index + '.file'"
          :rules="{
            required: true,
            message: '标签设计稿不能为空',
            trigger: 'blur',
          }"
        >
          <div class="up_file_li">
            <div class="file_info">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :data="{ type: 4 }"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, domain, 1);
                  }
                "
                :on-success="
                  (res) => {
                    return handleSuccess(res, domain, 1);
                  }
                "
                :on-preview="open_file"
                :before-upload="
                  (res) => {
                    return beforeUpload(res, 1);
                  }
                "
                :limit="5"
                :file-list="domain.refile"
                accept=".jpg, .jpeg, .bmp, .png, .pdf, .doc, .excel, .txt, .docx, .xlsx"
                :http-request="(req) => uploadRequest(req, 4)"
                list-type="picture"
              >
                <el-tooltip effect="dark" placement="top-start">
                  <div slot="content">
                    支持.jpg .jpeg .bmp
                    .png格式的图片以及word、pdf、excel、txt文档<br />一款食品最多可上传5个标签设计文件<br />单个文件大小不超过100M。
                  </div>
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-tooltip>
                <div slot="tip" class="el-upload__tip hint">
                  <div class="text-orange">
                    提示：涉及多款产品的礼盒装标签，请在支付前联系客服修改价格
                  </div>
                </div>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item
          v-if="!$store.getters.isCooperationCustomer"
          label="出具CMA报告"
          :prop="'domains.' + index + '.cma.required'"
        >
          <el-switch
            v-model="domain.cma.required"
            active-text="是"
            inactive-text="否"
          />
        </el-form-item>

        <div class="group" v-if="domain.cma.required">
          <div class="text-xs text-orange margin-left-sm margin-bottom-xs">
            提示：以下信息将会出现在CMA报告中, 请按真实情况填写
          </div>
          <el-form-item
            v-if="domain.cma.required"
            label="委托单位名称"
            :prop="'domains.' + index + '.cma.requesterName'"
            :rules="{
              required: domain.cma.required,
              message: '必须填写体现委托单位名称',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="domain.cma.requesterName"
              placeholder="请填写委托单位名称"
            ></el-input
          ></el-form-item>
          <el-form-item
            v-if="domain.cma.required"
            label="委托单位地址"
            :prop="'domains.' + index + '.cma.requesterAddr'"
            :rules="{
              required: domain.cma.required,
              message: '必须填写委托单位地址',
              trigger: 'blur',
            }"
          >
            <el-input
              v-model="domain.cma.requesterAddr"
              placeholder="请填写委托单位地址"
            ></el-input
          ></el-form-item>
          <el-row>
            <el-col :span="12">
              <el-form-item
                v-if="domain.cma.required"
                label="委托联系人"
                :prop="'domains.' + index + '.cma.requesterContact'"
                :rules="{
                  required: domain.cma.required,
                  message: '必须填写委托联系人姓名',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="domain.cma.requesterContact"
                  placeholder="请填写委托联系人姓名"
                ></el-input
              ></el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                v-if="domain.cma.required"
                label="联系电话"
                :prop="'domains.' + index + '.cma.requesterContactTel'"
                :rules="{
                  required: domain.cma.required,
                  message: '必须填写委托联系人电话',
                  trigger: 'blur',
                }"
              >
                <el-input
                  v-model="domain.cma.requesterContactTel"
                  placeholder="请填写委托联系人电话"
                ></el-input
              ></el-form-item>
            </el-col>
          </el-row>
        </div>
        <el-form-item
          :label="'食品名称'"
          :prop="'domains.' + index + '.name'"
          :rules="{
            required: true,
            message: '请输入食品名称',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="domain.name"
            placeholder="请输入食品名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="规格/含净量"
          :prop="'domains.' + index + '.specs'"
          class="type_list"
          :inline="true"
        >
          <el-radio-group
            v-model="domain.format"
            @input="(label) => onChangeFormat(label, domain)"
          >
            <el-radio label="常用格式"></el-radio>
            <el-radio label="其他格式"></el-radio>
          </el-radio-group>
          <div v-if="domain.format === '常用格式'">
            <div class="dib li">
              <el-input
                v-model="domain.quantity"
                placeholder="请输入数量"
              ></el-input>
            </div>
            <div class="dib li unit">
              <el-select v-model="domain.unit" placeholder="单位">
                <el-option label="克" value="克"></el-option>
                <el-option label="千克" value="千克"></el-option>
                <el-option label="升" value="升"></el-option>
                <el-option label="毫升" value="毫升"></el-option>
              </el-select>
            </div>
            <span class="el-icon-close icon fwb"></span>
            <div class="dib li">
              <el-input v-model="domain.pieceNum" placeholder="请输入数量">
                <template slot="append">件</template>
              </el-input>
            </div>
          </div>
          <div v-else-if="domain.format === '其他格式'">
            <el-input
              v-model="domain.specs"
              placeholder="请输入产品规格/含净量"
            ></el-input>
          </div>
        </el-form-item>
        <el-form-item
          :label="'执行标准文件'"
          :prop="'domains.' + index + '.cp_file'"
        >
          <div class="up_file_li">
            <div class="file_info">
              <el-upload
                class="upload-demo"
                :action="uploadUrl"
                :data="{ type: 3 }"
                :on-remove="
                  (file, fileList) => {
                    handleRemove(file, fileList, domain, 2);
                  }
                "
                :on-success="
                  (res) => {
                    return handleSuccess(res, domain, 2);
                  }
                "
                :before-upload="
                  (res) => {
                    return beforeUpload(res, 2);
                  }
                "
                :limit="20"
                accept=".jpg, .jpeg, .bmp, .png, .pdf, .doc, .excel, .txt, .docx, .xlsx"
                :file-list="domain.resp_file"
                :on-preview="open_file"
                :http-request="(req) => uploadRequest(req, 3)"
                list-type="picture"
              >
                <el-tooltip effect="dark" placement="right">
                  <div slot="content">
                    <br />格式要求：支持.jpg .jpeg .bmp
                    .png格式的图片以及word、pdf、excel、txt文档<br />单个文件大小不超过100M
                    <br />最多可上传20个执行标准文件
                  </div>
                  <el-button size="small" type="primary">点击上传</el-button>
                </el-tooltip>
                <div slot="tip" class="el-upload__tip hint">
                  请上传企业标准文件。
                </div>
              </el-upload>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="'备注'">
          <el-input v-model="domain.bj" placeholder="备注信息"></el-input>
        </el-form-item>
      </div>
      <div
        v-if="$route.query.modifyTagId"
        class="text-center flex justify-center"
        style="margin-left: 120px"
      >
        <div>
          <el-button @click="$router.go(-1)" icon="el-icon-arrow-left"
            >暂不修改</el-button
          >
          <el-button type="primary" @click="submitForm"
            >提交标签修改资料
          </el-button>
        </div>
      </div>
      <template v-else>
        <a class="text-sm text-red text-bold" @click="addDomain"
          >添加更多食品标签</a
        >
        <div class="tac">
          <el-button type="primary" @click="submitForm">
            提交标签审核申请
            <i class="el-icon-arrow-right el-icon--right"></i>
          </el-button>
        </div>
      </template>
    </el-form>
  </div>
</template>

<script>
import { upload } from '@/assets/js/validate';
import { pictureOrc } from '@/http/interface';
import { cloneDeep } from 'lodash';

const blankForm = {
  name: '',
  sc_name: '',
  cp_file: [],
  bj: '',
  file: [],
  quantity: '',
  format: '常用格式',
  pieceNum: 1,
  unit: '克',
  cma: {},
  specs: '',
};

function extractSpecs(specs) {
  let obj = null;
  const regex = /\s*(\d+)\s*(克|千克|升|毫升){1}\s*[\\*xX]{1}\s*(\d+)件\s*/gm;
  const match = regex.exec(specs);
  if (match && match.length) {
    obj = {};
    obj.quantity = match[1];
    obj.unit = match[2];
    obj.pieceNum = match[3];
  }
  return obj;
}

export default {
  // watch: {
  //   'tag_form.domains': {
  //     handler(newItems) {
  //       newItems.forEach((item) => {
  //         if (item.format === '其他格式') {
  //           if (item.specs && item.quantity) {
  //             item.specs = item.pieceNum
  //               ? item.quantity + item.unit + 'x' + item.pieceNum + '件'
  //               : item.quantity + item.unit;
  //           }
  //         } else {
  //           const obj = extractSpecs(item.specs);
  //           obj.quantity && (item.quantity = obj.quantity);
  //           obj.unit && (item.unit = obj.unit);
  //           obj.pieceNum && (item.pieceNum = obj.pieceNum);
  //         }
  //       });
  //     },
  //     deep: true,
  //   },
  // },
  data() {
    return {
      uploadUrl: 'https://upload.qiniup.com/',
      file: {},
      tag_form: {
        domains: [cloneDeep(blankForm)],
      },
      fileList: [],
    };
  },
  computed: {
    lock() {
      return this.$store.state.common.apply;
    },
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    onChangeFormat(val, domain) {
      console.log('format:', val, domain);
      let item = domain;
      if (item.format === '其他格式') {
        if (item.quantity) {
          item.specs = item.pieceNum
            ? item.quantity + item.unit + 'x' + item.pieceNum + '件'
            : item.quantity + item.unit;
        }
      } else {
        const obj = extractSpecs(item.specs);
        if (obj) {
          item.quantity = obj.quantity;
          item.unit = obj.unit;
          item.pieceNum = obj.pieceNum;
        }
      }
    },
    resetTag(params = []) {
      if (!params.length) return;
      this.tag_form.domains = params.map((item) => {
        const standardFile =
          item.productStandard && item.productStandard.standardFile
            ? item.productStandard.standardFile
            : [];
        const foodLabels = item.foodLabels || [];
        const obj = {
          cp_file: standardFile,
          file: foodLabels,
          // 其他信息
          name: item.foodName,
          sc_name: item.manufacturer,
          bj: item.remark,
          specs: item.specs,
          quantity: '',
          format: '其他格式',
          refile: [],
          resp_file: [],
          cma: item.cma || {},
        };
        if (obj.specs) {
          console.log('specs:', obj.specs);
          const info = extractSpecs(obj.specs);
          if (info) {
            obj.quantity = info.quantity;
            obj.unit = info.unit;
            obj.pieceNum = info.pieceNum;
            obj.format = '常用格式';
          }
        }

        // 为了兼容upload组件追加
        if (foodLabels.length) {
          obj.refile = foodLabels.map((item) => {
            return {
              name: item.originalname,
              url: item.url,
              response: {
                data: item,
              },
            };
          });
        }
        if (standardFile.length) {
          obj.resp_file = standardFile.map((item) => {
            return {
              name: item.originalname,
              url: item.url,
              response: {
                data: item,
              },
            };
          });
        }
        return obj;
      });
    },
    open_file(file) {
      if (file.response) {
        window.open(file.response.data.url, '_blank');
      }
    },
    getFormatTag() {
      return this.tag_form.domains.map((item) => {
        let specs;
        if (item.format === '其他格式') {
          // if (!item.quantity) throw "请您将规格/含净量补充完整";
          specs = item.specs;
        } else {
          specs = item.pieceNum
            ? item.quantity + item.unit + 'x' + item.pieceNum + '件'
            : item.quantity + item.unit;
        }
        return {
          foodLabels: item.file,
          foodName: item.name,
          manufacturer: item.sc_name,
          specs: specs,
          productStandard: { standardFile: item.cp_file },
          remark: item.bj,
          cma: item.cma,
        };
      });
    },
    async submitForm() {
      const value = await this.$refs['tag_form'].validate();
      const tagList = this.getFormatTag();
      value && this.$emit('submit', tagList);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    removeDomain(item) {
      var index = this.tag_form.domains.indexOf(item);
      if (index !== -1) {
        this.tag_form.domains.splice(index, 1);
      }
    },
    addDomain() {
      let domain = cloneDeep(blankForm);
      if (this.tag_form.domains.length >= 1) {
        let lastDomain =
          this.tag_form.domains[this.tag_form.domains.length - 1];
        domain.cma = cloneDeep(lastDomain.cma);
      }
      this.tag_form.domains.push(domain);
    },
    // 文件上传
    handleSuccess(response, i, status) {
      const url = response;
      try {
        if (status === 1) {
          i.file.push(url.data);
          if (url.data.mimetype.includes('image/'))
            pictureOrc({ url: url.data.url })
              .then(() => console.log('orc cash finish'))
              .catch((ex) => console.log('错误信息:', ex));
        } else if (status === 2) {
          i.cp_file.push(url.data);
        }
      } catch (err) {
        this.$message.error('文件上传出现错误，请重新上传~');
      }
    },
    beforeUpload(file, status) {
      let fileName = file.name;
      let regex = /(.pdf|.docx|.doc|.txt|.excel|.jpg|.jpeg|.bmp|.png|.xlsx)$/;
      if (!regex.test(fileName.toLowerCase())) {
        this.$message.error('该文件类型暂未支持,请选中正确文件!');
        return false;
      }
      const imageSize = file.size / 1024 / 1024 < 100; //上传图片大小不超过10M
      if (!imageSize) {
        this.$message.error('上传文件大小不能超过 100MB!');
        return false;
      }
      return imageSize;
    },
    handleRemove(file, fileList, i, status) {
      try {
        if (status === 1) {
          let arr = [];
          fileList.forEach((e) => {
            arr.push(e.response.data);
          });
          i.file = arr;
        } else if (status === 2) {
          let arr = [];
          fileList.forEach((e) => {
            arr.push(e.response.data);
          });
          i.cp_file = arr;
        }
      } catch (err) {
        this.$message.error('文件上传出现错误，请重新上传~');
      }
    },
    handlePreview(file) {
      const regex = /(.jpg|.jpeg|.bmp|.png)$/;
      if (regex.test(file.originalname)) {
        const h = this.$createElement;
        this.$msgbox({
          title: `预览图片：${file.originalname}`,
          message: h('div', {
            src: file.url,
            title: '查看图片',
            style: {
              width: '100%',
              minHeight: '300px',
              height: 'auto',
              textAlign: 'center',
              fontSize: 0,
            },
            domProps: {
              innerHTML: `<img style="width:100%;height:auto;" src="${file.url}"></img>`,
            },
          }),
        });
      } else {
        window.open(file, url, '_blank');
      }
    },
    uploadRequest(request, fileType = 4) {
      request.user = this.user;
      request.type = fileType;
      let that = this;
      that.$api
        .upload_get({
          type: request.type,
        })
        .then((res) => {
          let token = res.data.token;
          upload(
            token,
            request,
            (next) => {
              request.file.percent = next.total.percent;
              request.onProgress(request.file);
            },
            (error) => {
              console.log(error);
            },
            (complete) => {
              let hash = complete.hash;
              let key = complete.key;
              let data = {
                data: complete,
              };
              request.onSuccess(data);
            }
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.norm_info_box {
  width: 700px;
  margin: auto;
  margin-top: 20px;
  padding: 20px 50px;

  background: #fff;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  .group {
    width: 600px;
    background: #f2f2f2;
    margin: 0px auto 20px;
    padding: 20px 20px 0 0;
    border-radius: 4px;
  }

  .norm_list {
    .remove_btn {
      .el-button {
        font-size: 20px;
      }
    }

    .type_list {
      .li {
        margin-right: 5px;
        width: 210px;
      }

      .unit {
        width: 80px;
      }

      .icon {
        font-size: 16px;
      }
    }

    .up_file_li {
      .hint {
        font-size: 12px;
        color: #606266;
        line-height: 1.5;
      }
    }
  }
}
</style>
