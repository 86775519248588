var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top-sm"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"type":"index","width":"40"}}),_c('el-table-column',{attrs:{"prop":"foodName","width":"250"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('span',{staticClass:"text-red"},[_vm._v("*")]),_vm._v(" 食品名称 ")]}},{key:"default",fn:function(scope){return [_c('el-input',{staticClass:"input-height",attrs:{"placeholder":"请输入食品名称","size":"small","type":"textarea"},model:{value:(scope.row.foodName),callback:function ($$v) {_vm.$set(scope.row, "foodName", $$v)},expression:"scope.row.foodName"}})]}}])}),_c('el-table-column',{attrs:{"prop":"foodLabels"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_c('span',{staticClass:"text-red"},[_vm._v("*")]),_vm._v(" 设计稿 "),_c('el-tooltip',{attrs:{"placement":"right-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 请上传标签设计文件。（最多只能上传五份）"),_c('br'),_vm._v(" 格式要求：支持.jpg .jpeg .bmp .png格式的图片以及word、pdf、excel、txt文档。"),_c('br'),_vm._v(" 单个食品标签最多可上传5个设计文件，单个文件大小不超过100M。 ")]),_c('span',{staticClass:"el-icon text-black el-icon-question text-xs"})])]}},{key:"default",fn:function(scope){return [_c('batchUpload',{attrs:{"fileType":"img","data":{
            type: 3,
            user: _vm.user,
          },"limit":5},model:{value:(scope.row.foodLabels),callback:function ($$v) {_vm.$set(scope.row, "foodLabels", $$v)},expression:"scope.row.foodLabels"}})]}}])}),_c('el-table-column',{attrs:{"prop":"standardFile"},scopedSlots:_vm._u([{key:"header",fn:function(scope){return [_vm._v(" 执行标准 "),_c('el-tooltip',{attrs:{"placement":"right-start"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_vm._v(" 请上传企业标准文件。（可将该标准分成不超过20个文件上传）"),_c('br'),_vm._v(" 格式要求：支持.jpg .jpeg .bmp .png格式的图片以及word、pdf、excel、txt文档。"),_c('br'),_vm._v(" 单个文件大小不超过100M ")]),_c('span',{staticClass:"el-icon text-black el-icon-question text-xs"})])]}},{key:"default",fn:function(scope){return [_c('batchUpload',{attrs:{"data":{
            type: 4,
            user: _vm.user,
          },"limit":20},model:{value:(scope.row.standardFile),callback:function ($$v) {_vm.$set(scope.row, "standardFile", $$v)},expression:"scope.row.standardFile"}})]}}])}),_c('el-table-column',{attrs:{"prop":"specs","label":"规格/含净量","width":"300"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('InputSpec',{model:{value:(scope.row.specs),callback:function ($$v) {_vm.$set(scope.row, "specs", $$v)},expression:"scope.row.specs"}})]}}])}),_c('el-table-column',{attrs:{"prop":"remark","label":"备注","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{staticClass:"input-height",attrs:{"placeholder":"备注信息","size":"small","type":"textarea"},model:{value:(scope.row.remark),callback:function ($$v) {_vm.$set(scope.row, "remark", $$v)},expression:"scope.row.remark"}})]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"操作","width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('a',{staticClass:"text-red text-center text-df",attrs:{"disabled":!_vm.isDisabled},on:{"click":function($event){return _vm.removeColumn(scope)}}},[_c('span',{staticClass:"el-icon-delete-solid"})])]}}])})],1),(_vm.$route.query.modifyTagId)?_c('div',{staticClass:"text-center margin-top-sm"},[_c('div',[_c('el-button',{attrs:{"icon":"el-icon-arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("暂不修改")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitTag}},[_vm._v("提交标签修改资料 ")])],1)]):[_c('div',{staticClass:"margin-top-sm"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.tableData.push({})}}},[_vm._v("新增一行")])],1),_c('div',{staticClass:"text-center margin-top"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submitTag}},[_vm._v("提交标签申请审核 "),_c('span',{staticClass:"el-icon el-icon-arrow-right"})])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }