<template>
  <div class="container">
    <div class="text-lg text-center text-bold margin-bottom">
      预包装食品标签（电子版本）委托检验协议
    </div>
    <table class="table table-bordered">
      <tbody>
        <tr>
          <td>委托单位名称</td>
          <td>{{ cma.requesterName }}</td>
          <td>委托单位地址</td>
          <td>{{ cma.requesterAddr }}</td>
        </tr>
        <tr>
          <td>委托方联系人</td>
          <td>{{ cma.requesterContact }}</td>
          <td>联系电话</td>
          <td>{{ cma.requesterContactTel }}</td>
        </tr>
        <tr>
          <td>检验报告用途</td>
          <td>委托检验</td>
          <td>报告形式</td>
          <td>电子版本</td>
        </tr>
        <tr>
          <td>检验项目及检验方法</td>
          <td colspan="3">
            检验项目：标签。检验方法由重庆士海科技有限公司自行决定。
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <span class="text-bold text-black">委托方声明：</span
            >我方保证所提供的所有相关信息、资料和实物的真实性、合法性，如有虚假承担所有责任。我方同意检验及其他服务按此协议的条件进行，并在检验开始前付清所需的费用和提供必要的合作。
            <div class="text-right">
              委托方授权代表人签名：
              <span class="text-black">{{ cma.requesterContact }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            本公司保证检验的公正性，对检验数据负责，并对委托方产品的机密信息及技术资料保密。本公司的检验数据仅对来样负责。
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <span class="text-bold text-black">声明：</span>
            <ul>
              1. 实验室将依据本表中的信息出具检验报告，请确保信息准确。
            </ul>
            <ul class="text-bold text-black">
              2.
              实验室仅对产品标识标签的完整性、规范性进行核查，不对产品的实物与标签标识内容真实性进行检验。
            </ul>
            <ul>
              3.
              报告默认为中文格式，如需出具英文报告，请委托方提供准确的英文信息。
            </ul>
            <ul>
              4.
              报告修改需加收费用100元/份。报告签发之日起30日（含）后不再受理报告更改的请求。
            </ul>
            <ul>
              5. 请在传送样品时随附该委托检验协议。
            </ul>
            <ul>
              6. 请仔细阅读“通用服务条款”，委托方的签字表明已阅读并认可该条款。
            </ul>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <span class="text-bold text-black"> 通用服务条款 </span>
            <ul>
              （一）样品信息真实性的责任。委托方应真实、详尽、毫无隐瞒地将委托检验及检验报告所需信息和资料提供给本公司。委托方对所提供的包括但不限于委托方名称、地址、样品名称、规格型号、生产单位、商标、生产日期/批号等所有与样品检验有关的信息和资料的正确性、真实性、合法性负责。因委托方提供的信息和资料存在虚假、误导、隐瞒、重大遗漏而造成的损失，由委托方承担全部责任。
            </ul>
            <ul>
              （二）协议信息完整性的责任。委托检验协议中委托方信息、样品信息、检验项目为必填项，如委托方不填写，则默认由承检方决定，由此而导致的相关责任由委托方承担。本委托检验协议一旦生效，协议上的样品名称、生产日期/批号、生产商信息不得随意更改。
            </ul>
            <ul>
              （三）协议变更。本委托检验协议的更改应以书面方式进行，填写本公司的《标书、合同修改偏离记录》，经本公司重新进行协议评审后认为可行的，方可进行协议更改。协议更改后即按更改后的协议执行，测试时间从更改协议生效时重新计算。
            </ul>
            <ul>
              （四）撤销委托检验协议或取消部分检验项目，应在全部或拟取消的检验项目尚未开始检验前进行。
            </ul>
            <ul>
              （五）检验周期。<span class="text-bold text-black"
                >本公司自“收到样品和完整的委托检验协议”起开始计算检验周期，一般为2个工作日，特殊情况另定。</span
              >在约定期间内无法出具检验报告的，承检方应提前告知委托方延迟的原因，并另行商定出具检验报告的时间。
            </ul>
            <ul>
              （六）报告更改。委托方填写信息不清楚或有误而导致报告的更改，由委托方负责。委托方应当向本公司提出书面申请修改，需填写本公司《检验报告更改（补发）申请表》，经本公司审核同意后予以重新出具检验报告。
            </ul>
            <ul>
              （七）报告有效性。本公司检验报告上的结果数据只对所送检的样品负责。本公司的检验报告须有授权签字人签字并加盖“重庆士海科技有限公司检验检测专用章”，涂改无效，对无授权人签字或未加盖本公司检验检测专用章的检测报告，本公司一律不予认可。
            </ul>
            <ul>
              （八）报告复制。本公司默认提供一份检验报告原件，若委托方要求提供多份检验报告，请在委托协议注明，每份加收50元。
            </ul>
            <ul>
              （九）现场测试。如委托方需要本公司外出采样或现场测试，委托方应另外支付费用并确保采样场所不存在任何可能危及或影响采样人员人身、财产安全的危险因素。否则，由此给采样人员和/或测试单位造成的一切损失（包括但不限于医疗费用、工伤待遇、经济赔偿）由委托方承担，除非另有协议约定。
            </ul>
            <ul>
              （十）样品保存。如委托方对检验完毕的样品无退样要求，则由本公司处置。对检验完毕的样品按本公司样品管理程序规定期限保存，保留期限过后，本公司有权自行处置。
            </ul>
            <ul>
              （十一）异议解决。委托方如对检验结果有异议，应在报告签发后7个工作日内提出。如委托方对检验结果有异议而提出复检要求时，仅限对原样品按原测试方法进行复检；如果委托方换用新的样品和/或提出改变方法进行测试，则视作新的委托要求，委托方应支付相应测试费用。如复检结果与原结果在方法允许的误差范围内一致，则委托方需要支付与原测试项目收费标准等额的复检费用。如复检结果确认原检验结果有误的，本公司不再收取测试费用并换发新的检验报告。对下述情况，本公司不受理复检：1)原样品超过保存期限已被销毁；2)其他依据法律法规规定或行业规定、行业习惯不应受理的情形。
            </ul>
            <ul>
              （十二）不可抗力。因灾害、事故等不可抗力原因而造成样品损失或损坏，导致服务延迟或不能履行时，本公司会在合理的时间内通知委托方取消或暂停服务而无需承担违约责任。
            </ul>
            <ul>
              （十三）此委托检验协议的复印件和传真件均有效。
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'PrincipalContract',
  props: {
    cma: {
      type: Object,
      default: () => {},
    },
  },
  date: () => {
    return {

      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
  border: 3px solid #aaa;
  //   宋体
  //   font-family: SimSun;
  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    text-align: left;
  }
  th {
    font-weight: bold;
  }
  ul {
    margin: 0;
    padding: 3px 0;
    list-style: none;
  }
}
</style>
